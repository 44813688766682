'use client';
import Image from 'next/image';
import React, { useEffect, useState } from 'react';

import RiskApproachCard from '@/app/_components/risk-approach-card/RiskApproachCard';
import TimeLine from '@/app/_components/timeline/Timeline';
import VideoPlayer from '@/app/_components/video/VideoPlayer';
import { AboutUsVariation4 } from '@/app/_types/about-us';
import { FilesMeta } from '@/app/_types/file';
import { getAltProperty, getDirectusAssetURI } from '@/app/_utils/assets';

import CtaBanner from './CtaBanner';
import Styles from './styles.module.scss';

type Props = AboutUsVariation4 & {
  filesMeta: FilesMeta;
};
const AboutPageVariation4 = (props: Props) => {
  const { header, content, video, history_title, history, filesMeta, banner } =
    props;

  const [scrollPosition, setScrollPosition] = useState(0);

  const timeLineData = {
    history_title,
    history,
  };

  useEffect(() => {
    const handleScroll = () => {
      const scroll = window.scrollY;

      if (scroll >= 250) {
        setScrollPosition(scroll - 250);
      } else {
        setScrollPosition(0);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const zoomStyle = {
    transform: `scale(${1 + scrollPosition / 4000})`,
  };

  return (
    <>
      {header && <CtaBanner {...header} />}
      {content &&
        content?.length > 0 &&
        content?.map((value, index) => {
          return (
            <RiskApproachCard
              key={index}
              {...value.copy_component_id}
              filesMeta={filesMeta}
            />
          );
        })}
      {timeLineData && <TimeLine {...timeLineData} />}
      {video && <VideoPlayer video={video} />}
      {banner && !video && (
        <div className={Styles.bannerImageContainer}>
          <div className={Styles.bannerImageWrapper} style={zoomStyle}>
            <Image
              src={getDirectusAssetURI(banner) || ''}
              alt={getAltProperty(filesMeta, banner ?? '', 'Banner')}
              width={1920}
              height={640}
              className={Styles.bannerImage}
              priority
            />
          </div>
        </div>
      )}
    </>
  );
};

export default AboutPageVariation4;
