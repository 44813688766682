import cn from 'classnames';
import React from 'react';

import { AboutUsVariation2 } from '@/app/_types/about-us';

import Styles from './CtaBanner.module.scss';

const CtaBanner = (props: AboutUsVariation2['header']) => {
  const { title, paragraph, title_2 } = props;

  return (
    <div className="container">
      <div className={Styles.ctaBannerWrapper}>
        <div className={Styles.ctaBannerContent}>
          <div className="headingElement">
            {title && (
              <h1
                className={cn(
                  'titleXXl text-primary strong',
                  Styles.ctaBannerTitle,
                )}
              >
                <span className="light">{title}</span>
                {title_2}
              </h1>
            )}
          </div>
        </div>

        {paragraph && (
          <div
            className={cn(Styles.description, 'editorDescription')}
            dangerouslySetInnerHTML={{
              __html: paragraph,
            }}
          ></div>
        )}
      </div>
    </div>
  );
};

export default CtaBanner;
