'use client';

import cn from 'classnames';
import Image from 'next/image';
import React, { useEffect, useState } from 'react';

import ButtonCustom from '@/app/_components/button/ButtonCustom';
import { HeaderComponent } from '@/app/_types/components/header-component';
import { FilesMeta } from '@/app/_types/file';
import {
  getAltProperty,
  getDirectusAssetURI,
  getDirectusDownloadAssetURI,
  getProxiedDownloadAssetURI,
} from '@/app/_utils/assets';

import Styles from './CtaBanner.module.scss';

type CtaBannerProps = HeaderComponent & {
  filesMeta: FilesMeta;
};
const CtaBanner = (props: CtaBannerProps) => {
  const {
    static_title,
    paragraph,
    image,
    cta_button,
    dynamic_titles,
    filesMeta,
  } = props;
  const [scrollPosition, setScrollPosition] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const pdfMeta = cta_button?.pdf ? filesMeta[cta_button?.pdf] : undefined;

  useEffect(() => {
    const handleScroll = () => {
      const scroll = window.scrollY;

      if (scroll >= 175) {
        setScrollPosition(scroll - 175);
      } else {
        setScrollPosition(0);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const zoomStyle = {
    transform: `scale(${1 + scrollPosition / 4000})`,
  };

  useEffect(() => {
    const titles = props?.dynamic_titles || [];

    if (titles.length > 0) {
      const intervalId = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % titles.length);
      }, 2000);

      return () => clearInterval(intervalId);
    }
  }, [props?.dynamic_titles]);

  return (
    <div className={Styles.ctaBannerWrapper}>
      <div className="container">
        <div className={Styles.ctaBannerContent}>
          <div
            className={cn('headingElement', Styles.ctaBannerHeadingContainer)}
          >
            {(static_title ||
              (dynamic_titles && dynamic_titles?.length > 0)) && (
              <h1 className="titleXXl full-width">
                {static_title && <span className="light">{static_title}</span>}
                <span className={cn('headingElement', Styles.offeringText)}>
                  {dynamic_titles &&
                    dynamic_titles?.length &&
                    dynamic_titles.map((text, index) => (
                      <span
                        key={index}
                        className={cn(
                          Styles.offeringTextItem,
                          index === currentIndex && Styles.active,
                        )}
                      >
                        {text.option}
                      </span>
                    ))}
                </span>
              </h1>
            )}
          </div>

          {paragraph && (
            <div
              className={cn(Styles.description, 'editorDescription')}
              dangerouslySetInnerHTML={{
                __html: paragraph,
              }}
            ></div>
          )}
          {cta_button?.text && (
            <ButtonCustom
              btnText={cta_button.text}
              href={
                cta_button?.pdf
                  ? getProxiedDownloadAssetURI(pdfMeta) || getDirectusDownloadAssetURI(cta_button?.pdf ?? '') || ''
                  : cta_button.url || undefined
              }
              newTab={cta_button?.new_tab}
            />
          )}
        </div>
      </div>
      {image && (
        <div className={Styles.bannerImageContainer}>
          <div className={Styles.bannerImageWrapper} style={zoomStyle}>
            <Image
              src={getDirectusAssetURI(image ?? '') || ''}
              alt={getAltProperty(filesMeta, image ?? '', 'Banner image')}
              fill
              className={Styles.bannerImage}
              priority
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CtaBanner;
