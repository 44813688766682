'use client';
import { usePathname } from 'next/navigation';

import BreadcrumbCustom from '@/app/_components/breadcrumb/BreadcrumbCustom';
import { Blog } from '@/app/_types/blog';
import { Breadcrumb } from '@/app/_types/breadcrumbs';
import { MediaResource } from '@/app/_types/components/media-resources';
import { FilesMeta } from '@/app/_types/file';
import { getDirectusDownloadAssetURI } from '@/app/_utils/assets';

import ArticleContent from './ArticleContent';
import CtaBanner from './CtaBanner';
import RelatedArticles from './RelatedArticles';

type Props = MediaResource & {
  breadcrumbs?: Breadcrumb[] | null;
  filesMeta: FilesMeta;
  relatedArticles?: Blog[] | null | MediaResource[];
  description?: string;
};

const MediaDetailPage = (props: Props) => {
  const {
    title,
    date,
    author,
    banner_image,
    cta_banner,
    relatedArticles,
    breadcrumbs,
    description,
    documents,
    filesMeta,
  } = props;

  const path = usePathname();

  const relatedDynamicURl = path.substring(0, path.lastIndexOf('/'));

  const documentList = documents
    ?.map((documentM2M) => {
      const document = documentM2M.directus_files_id;

      if (document && document.id) {
        return {
          title: document.title || document.id,
          url: getDirectusDownloadAssetURI(document.id, true) || undefined,
        };
      }
    })
    .filter((doc) => doc);

  return (
    <>
      {breadcrumbs && <BreadcrumbCustom items={breadcrumbs} />}
      <CtaBanner
        title={title}
        date={date}
        author={author}
        bannerImage={banner_image}
      />
      <ArticleContent
        description={description}
        ctaBanner={cta_banner}
        documents={documentList}
        filesMeta={filesMeta}
      />
      {relatedArticles && relatedArticles.length > 0 && (
        <RelatedArticles
          media={relatedArticles}
          blogBaseUrl={relatedDynamicURl}
        />
      )}
    </>
  );
};

export default MediaDetailPage;
