'use client';
import Image from 'next/image';
import React, { useState } from 'react';

import { Button } from '@/app/_types/components/button';
import { LocationCard } from '@/app/_types/components/location-card';
import { Vendor } from '@/app/_types/components/vendor';
import { VendorBranch } from '@/app/_types/components/vendor-branch';
import { VendorCategory } from '@/app/_types/components/vendor-category';
import { VendorOffer } from '@/app/_types/components/vendor-offering';
import { FilesMeta } from '@/app/_types/file';
import { CompanyCard } from '@/app/_types/find-service-provider';
import { getAltProperty, getDirectusAssetURI } from '@/app/_utils/assets';

import SearchCompanyForm from './company/SearchCompanyForm';
import SearchByService from './service/SearchByService';
import Styles from './ServiceSection.module.scss';

type ServiceSectionType = {
  preText: string;
  title: string;
  paragraph: string;
  vendorCategory: VendorCategory;
  vendorOffering?: VendorOffer;
  vendor?: Vendor;
  vendorBranch?: VendorBranch;
  cards: CompanyCard[];
  filesMeta: FilesMeta;
  searchCompanyform: any;
  backButton?: Button;
  nextButton?: Button;
  companyResultPreText?: string;
  companyResultTitle?: string;
  companyResultParagraph?: string;
  serviceResultPretext?: string;
  serviceResultTitle?: string;
  serviceResultParagraph?: string;
  headOffice?: LocationCard | null;
  noVendorIcon: string;
  noVendorTitle: string;
  noVendorParagraph: string;
  backToStartButton: Button;
  step1PreText?: string;
  step1Title?: string;
  step1Paragraph?: string;
  step2PreText?: string;
  step2Title?: string;
  step2Paragraph?: string;
  step3PreText?: string;
  step3Title?: string;
  step3Paragraph?: string;
  vendorDetailNavigation: string | undefined | null;
};

const ServiceSection = (props: ServiceSectionType) => {
  const {
    preText,
    title,
    paragraph,
    cards,
    filesMeta,
    searchCompanyform,
    backButton,
    vendorCategory,
    vendorOffering,
    vendor,
    nextButton,
    vendorBranch,
    companyResultPreText,
    companyResultTitle,
    companyResultParagraph,
    serviceResultPretext,
    serviceResultTitle,
    serviceResultParagraph,
    headOffice,
    noVendorIcon,
    noVendorTitle,
    noVendorParagraph,
    backToStartButton,
    step1PreText,
    step1Title,
    step1Paragraph,
    step2PreText,
    step2Title,
    step2Paragraph,
    step3PreText,
    step3Title,
    step3Paragraph,
    vendorDetailNavigation,
  } = props;

  const [IsServiceSelected, setIsServiceSelected] = useState(false);
  const [selectedService, setSelectedService] = useState<string | null>(null);

  const handleBack = () => {
    setIsServiceSelected(false);
    setSelectedService(null);
  };

  return (
    <div className="pageSection">
      <div className="container">
        {IsServiceSelected == false ? (
          <div className={Styles.serviceSectionWrapper}>
            <div className={Styles.sectionHeadingContainer}>
              <div className="headingElement">
                {preText && <h3 className="subTitle">{preText}</h3>}
                {title && <h2 className="titleXl">{title}</h2>}
              </div>
              {paragraph && (
                <div
                  className={Styles.sectionDescription}
                  dangerouslySetInnerHTML={{
                    __html: paragraph,
                  }}
                ></div>
              )}
            </div>

            {cards && (
              <div
                className={Styles.cardsList}
                onClick={() => {
                  setIsServiceSelected(true);
                }}
              >
                {cards.map((card, index) => (
                  <div
                    className={Styles.productCard}
                    key={index}
                    onClick={() => setSelectedService(card?.title ?? '')}
                  >
                    <Image
                      src={getDirectusAssetURI(card?.image ?? '') || ''}
                      alt={getAltProperty(
                        filesMeta,
                        card?.image ?? '',
                        'Banner',
                      )}
                      width={50}
                      height={50}
                    />
                    <div className={Styles.productCardBody}>
                      {card?.title && (
                        <div className="headingElement">
                          <h3 className="titleMd">{card?.title}</h3>
                        </div>
                      )}

                      {card?.paragraph && (
                        <div
                          className={Styles.description}
                          dangerouslySetInnerHTML={{ __html: card?.paragraph }}
                        ></div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ) : (
          <div>
            {selectedService?.toLowerCase().includes('company') && (
              <SearchCompanyForm
                {...searchCompanyform}
                vendor={vendor ?? []}
                backButton={backButton}
                nextButton={nextButton}
                vendorBranch={vendorBranch ?? []}
                onBack={handleBack}
                companyResultPreText={companyResultPreText ?? ''}
                companyResultTitle={companyResultTitle ?? ''}
                companyResultParagraph={companyResultParagraph ?? ''}
                headOffice={headOffice}
                noVendorIcon={noVendorIcon}
                noVendorTitle={noVendorTitle}
                noVendorParagraph={noVendorParagraph}
                backToStartButton={backToStartButton}
                vendorDetailNavigation={vendorDetailNavigation}
              />
            )}
            {selectedService?.toLowerCase().includes('service') && (
              <SearchByService
                vendorOffering={vendorOffering ?? []}
                vendor={vendor ?? []}
                vendorBranch={vendorBranch ?? []}
                gridItemsData={vendorCategory}
                backToStartUrl={backButton}
                filesMeta={filesMeta}
                serviceResultPretext={serviceResultPretext ?? ''}
                serviceResultTitle={serviceResultTitle ?? ''}
                serviceResultParagraph={serviceResultParagraph ?? ''}
                headOffice={headOffice}
                noVendorIcon={noVendorIcon}
                noVendorTitle={noVendorTitle}
                noVendorParagraph={noVendorParagraph}
                backToStartButton={backToStartButton}
                step1PreText={step1PreText}
                step1Title={step1Title}
                step1Paragraph={step1Paragraph}
                step2PreText={step2PreText}
                step2Title={step2Title}
                step2Paragraph={step2Paragraph}
                step3PreText={step3PreText}
                step3Title={step3Title}
                step3Paragraph={step3Paragraph}
                vendorDetailNavigation={vendorDetailNavigation}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ServiceSection;
