'use client';

import React, { useEffect, useRef, useState } from 'react';

import { ContactComponent } from '@/app/_types/components/contact-component';
import { CopyComponent } from '@/app/_types/components/copy-component';
import { DocumentsComponent } from '@/app/_types/components/documents-component';
import { Form } from '@/app/_types/components/form';
import {
  ContactM2A,
  CopyM2A,
  DocumentsM2A,
  FormM2A,
} from '@/app/_types/components/legal-card';
import { FilesMeta } from '@/app/_types/file';
import { LegalCardsM2M } from '@/app/_types/legal';
import { getProxiedDownloadAssetURI } from '@/app/_utils/assets';

import ContactForm from './ContactForm';
import Styles from './LegalDocuments.module.scss';

type Props = {
  cards: LegalCardsM2M[];
  section?: string;
  filesMeta?: FilesMeta;
};

const LegalDocuments = (props: Props) => {
  const { cards, section, filesMeta = {} } = props;
  const [activeCardSlug, setActiveCardSlug] = useState<string | null>(
    section || null,
  );
  const contentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const type = params.get('section');

    setActiveCardSlug(type);
  }, []);

  useEffect(() => {
    if (contentRef.current) {
      const topOffset = 120;
      const elementPosition = contentRef.current.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.scrollY - topOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  }, [activeCardSlug]);

  const handleCardClick = (slug: string | null) => {
    if (slug) {
      setActiveCardSlug(slug);
      const newUrl = `${window.location.pathname}?section=${slug}`;

      window.history.pushState(null, '', newUrl);
    }
  };

  const renderContentDetail = (
    cardDetail: CopyM2A | DocumentsM2A | ContactM2A | FormM2A,
  ) => {
    let copy: CopyComponent | null = null;
    let documents: DocumentsComponent | null = null;
    let contact: ContactComponent | null = null;
    let form: Form | null = null;

    switch (cardDetail.collection) {
      case 'copy_component':
        copy = cardDetail.item as CopyComponent;
        break;
      case 'documents_component':
        documents = cardDetail.item as DocumentsComponent;
        break;
      case 'contact_component':
        contact = cardDetail.item as ContactComponent;
        break;
      case 'Form':
        form = cardDetail.item as Form;
        break;
    }

    return (
      <React.Fragment key={cardDetail.id}>
        {copy && (
          <div
            className={Styles.legalDocumentsContent}
            dangerouslySetInnerHTML={{ __html: copy.paragraph || '' }}
          />
        )}
        {documents && (
          <div className={Styles.documentsList}>
            <div className={Styles.documentItem}>
              {documents.title && (
                <div className="headingElement">
                  <h3 className="titleMd">{documents.title}</h3>
                </div>
              )}

              {documents.files &&
                documents.files.map((file) => {
                  const metaData = file.directus_files_id ? filesMeta[file.directus_files_id] : undefined;
                  const fileTitle =
                    (metaData && metaData.title) || file.directus_files_id;

                  return (
                    <div key={file.id} className={Styles.documentItemBox}>
                      <p className={Styles.documentItemTitle}>{fileTitle}</p>
                      <div className={Styles.documentItemDownloadSection}>
                        <a
                          href={getProxiedDownloadAssetURI(metaData, true) || ''}
                          target="_blank"
                          className={Styles.documentItemDownloadLink}
                          download={fileTitle}
                        >
                          Download
                        </a>
                        <a
                          href={getProxiedDownloadAssetURI(metaData) || ''}
                          target="_blank"
                          className={Styles.documentItemDownloadLink}
                          rel="noopener noreferrer"
                        >
                          Preview
                        </a>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
        {contact && (
          <div className={Styles.contactContainer}>
            {contact.emails && (
              <div className={Styles.contactBox}>
                <div className={Styles.contactTitle}>
                  {contact.emails_title}
                </div>
                <div className={Styles.contactLinks}>
                  {contact.emails.map((email, index) => (
                    <a key={index} href={`mailto:${email.address}`}>
                      {email.name} {email.address}
                    </a>
                  ))}
                </div>
              </div>
            )}
            {contact.numbers && (
              <div className={Styles.contactBox}>
                <div className={Styles.contactTitle}>
                  {contact.numbers_title}
                </div>
                <div className={Styles.contactLinks}>
                  {contact.numbers.map((number, index) => (
                    <a key={index} href={`mailto:${number.number}`}>
                      {number.name} {number.number}
                    </a>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
        {form && <ContactForm {...form} filesMeta={filesMeta} />}
      </React.Fragment>
    );
  };

  const renderContent = () => {
    const activeCard =
      cards &&
      cards.find((cardM2M) => cardM2M.legal_cards_id.slug === activeCardSlug);

    if (!activeCard) return null;

    const card = activeCard.legal_cards_id;

    return (
      <div className={Styles.legalDocumentsContentCard} ref={contentRef}>
        <div className="headingElement">
          <h2 className="titleXl">{card.title}</h2>
        </div>
        <div className={Styles.legalDocumentsContentBox}>
          {card.detail &&
            card.detail.map((detail) => renderContentDetail(detail))}
        </div>
      </div>
    );
  };

  return (
    <div className="pageSection">
      <div className="container">
        <div className={Styles.legalDocumentsWrapper}>
          <div className={Styles.legalDocumentsCardsList}>
            {cards &&
              cards.map((cardM2M) => {
                const item = cardM2M.legal_cards_id;

                return (
                  <div
                    key={item.slug}
                    className={`${Styles.legalDocumentsCard} ${activeCardSlug === item.slug ? Styles.active : ''}`}
                    onClick={() => handleCardClick(item.slug)}
                  >
                    <div className="headingElement">
                      <h3 className="titleMd">{item.title}</h3>
                    </div>
                    <div className={Styles.legalDocumentsCardDescription}>
                      <p>{item.paragraph}</p>
                    </div>
                  </div>
                );
              })}
          </div>

          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default LegalDocuments;
