'use client';
import Image from 'next/image';
import React, { useEffect, useState } from 'react';

import HelpCard from '@/app/_components/help-card/HelpCard';
import VideoPlayer from '@/app/_components/video/VideoPlayer';
import { AboutUsVariation3 } from '@/app/_types/about-us';
import { FilesMeta } from '@/app/_types/file';
import { getAltProperty, getDirectusAssetURI } from '@/app/_utils/assets';

import CtaBanner from './CtaBanner';
import PageContent from './PageContent';
import Styles from './styles.module.scss';
import WhoWeAre from './WhoWeAre';

type Props = AboutUsVariation3 & {
  filesMeta: FilesMeta;
};
const AboutPageVariation3 = (_props: Props) => {
  const {
    header,
    video,
    call_to_action,
    tab_component,
    content,
    filesMeta,
    banner,
  } = _props;
  const [scrollPosition, setScrollPosition] = useState(0);

  const tabComponentData = {
    title: tab_component?.title,
    preText: tab_component?.pre_text,
    tabs: [
      {
        key: 'history',
        title: tab_component?.history_title,
        content: tab_component?.history,
      },
      {
        key: 'brand',
        title: tab_component?.brand_title,
        content: tab_component?.brand,
      },
      {
        key: 'leadership',
        title: tab_component?.leadership_title,
        content: tab_component?.leadership,
      },
    ],
  };

  useEffect(() => {
    const handleScroll = () => {
      const scroll = window.scrollY;

      if (scroll >= 250) {
        setScrollPosition(scroll - 250);
      } else {
        setScrollPosition(0);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const zoomStyle = {
    transform: `scale(${1 + scrollPosition / 4000})`,
  };

  return (
    <>
      {header && <CtaBanner {...header} filesMeta={filesMeta} />}
      {video && <VideoPlayer video={video} />}
      {banner && !video && (
        <div className="pageSection">
          <div className={Styles.bannerImageContainer}>
            <div className={Styles.bannerImageWrapper} style={zoomStyle}>
              <Image
                src={getDirectusAssetURI(banner) || ''}
                alt={getAltProperty(filesMeta, banner ?? '', 'Banner')}
                width={1920}
                height={640}
                className={Styles.bannerImage}
                priority
              />
            </div>
          </div>
        </div>
      )}
      {content &&
        content?.length > 0 &&
        content?.map((value, index) => {
          return (
            <PageContent key={index} {...value?.item} filesMeta={filesMeta} />
          );
        })}
      {tabComponentData && (
        <WhoWeAre
          {...tabComponentData}
          filesMeta={filesMeta}
          preText={tab_component?.pre_text ?? ''}
          title={tab_component?.title ?? ''}
        />
      )}
      {call_to_action && <HelpCard {...call_to_action} filesMeta={filesMeta} />}
    </>
  );
};

export default AboutPageVariation3;
